export const name =
`原材料の商品名を入力してください。<br><br>
一般的な食材名でも具体的な商品名でも問題ありません。 <br><br>
管理上「分かりやすい名前」を入力してください。`;

export const isProcessed =
`こちらを設定することにより以下に対応、また検索がしやすくなります。<br>
<br>
加工食品の場合、原材料の原料源原産地を「◯◯製造」（◯◯は国産、都道府県名、その他一般に知られている地名）とすることが定められています。<br>
<br>
例）りんご果汁（ドイツ製造）<br>
<br>
生鮮食品の場合、「〇〇」（◯◯は国産、都道府県名、その他一般に知られている地名）とすることが定められています。<br>
<br>
例）りんご（ドイツ）`;

export const displayName =
`ここに書かれた内容が、商品を作成する際に、そのまま表示されます。<br><br>
例えば、ここに「醤油」と入力した場合、商品でこの原材料を選択すると、以下のように表示されます。 <br><br>
◆ おにぎり<br>
原材料名：米、醤油`;

export const displayNameAdditiveFormulation = `「乳化剤製剤」や「香料製剤」など、複数の添加物や食品素材が混合された「製剤」の場合は、ご入力ください。`;

export const yieldPercent =
`仕入れている商品に対する「可食率」を入力します。<br><br>
例えば「パイナップルの缶詰」の「シロップ部分」を使用しない場合、可食率は数十％となります。<br><br>
計算が複雑になる場合は、原材料名に「パイナップル缶詰（シロップ破棄）」として、可食率100％にするのも有効です。`;

export const unit =
`ここで原材料の使用単位を指定することができます。<br><br>
これを指定することにより、例えば「中間原材料」や「商品」において、グラムやキログラム指定ではなく、にんじん 0.5本、のように指定することができます。<br><br>
指定方法は、にんじんの場合、1本あたりの重量、ここでは仮に「150g」を単位数量で指定し、次に単位名に「本」を指定します。`;

export const madeInAreaNumberPerishable =
`使用している原材料の産地数を入力してください。<br><br>
例えば、この原材料が「オレンジ」の場合、国内と国外のものを使用していいれば「2箇所」とします。<br><br>
頻繁に切り替わる場合は、実績の最も多い場合を入力してください。`;

export const madeInAreaNumberProcessed =
`この原材料の製造地数を入力してください。<br><br>
例えば、この原材料が「ケチャップ」の場合、国内と国外のものを使用していいれば「2箇所」とします。<br><br>
頻繁に切り替わる場合は、実績の最も多い場合を入力してください。`;

export const madeInAreaNamePerishable =
`産地が「2箇所」以上ある場合、使用する量の多い産地から入力する必要があります。<br><br>
原料原産地が「海外」の場合は、具体的な国名を選択する必要があります。<br><br>
また、「米（新潟）」のような具体的な地名を表記したい場合は、細かくルールが決められています。<br><br>
・農作物の場合、都道府県名、その他一般に知られている地名<br><br>
・畜産物の場合、主たる飼養地（最も飼養期間が長い場所）が属する都道府県名、その他一般に知られている地名<br><br>
・水産物は、生産（採取及び採捕を含む。）した水域名、水揚げした港名、水揚げした港、主たる養殖場（最も養殖期間が長い場所）が属する都道府県名、その他一般に知られている地名<br><br>
選択肢に用意されていないものは、「国内（自由入力）」「海外（自由入力）」を選択して、上記の条件に当てはまる文字列を入力してください。`;

export const madeInAreaNameProcessed =
`製造地が「2箇所」以上ある場合、使用する量の多い製造地から入力する必要があります。<br><br>
原料原産地が「海外」の場合は、具体的な国名を選択する必要があります。<br><br>
また、原材料に「ケチャップ（北海道製造）」のような具体的な地名を表記したい場合は、都道府県名、その他一般に知られている地名にすることが、定められています。<br><br>
選択肢に用意されていないものは、「国内（自由入力）」「海外（自由入力）」を選択して、上記の条件に当てはまる文字列を入力してください。`;

export const isCompositeHidden =
`例えば、マヨネーズは主に「卵・酢・油」などでできています。<br>
<br>
このように原材料表示名から、内容の大部分がイメージできる場合、食品表示において複合原材料の（）書きを省略することが認められています。<br>
<br>
ここにチェックを入れることで、その省略を実現することができます。<br>
<br>
---------------------------------<br>
【原材料名】マヨネーズ（卵、醸造酢、食用植物油脂）、砂糖（さとうきび、てん菜）、塩<br>
---------------------------------<br>
↓<br>
---------------------------------<br>
【原材料名】マヨネーズ、砂糖、塩<br>
---------------------------------`;

export const isCompositeCanBeSplitted =
`分割表示とは、複合原材料の中身が「原材料の性質に大きな変化がない」ものの場合、この複合原材料を使用した商品の原材料名に、それぞれの原材料を分割して表記することが可能です。<br>
<br>
例）◯ ココア調整品 → 砂糖、ココアパウダー、アーモンドパウダー<br>
<br>
ただし、性質が異なれば、原材料の分割での表示は認められません。<br>
<br>
例）✕ つぶあん→砂糖、小豆、水あめ、寒天`;

export const compositeItemName =
`商品でこの原材料を使用した際、食品表示の「原材料名」に記載する表示名を記述してください。<br>
<br>
---------------------------------<br>
【原材料名】○○、△△、マヨネーズ（食用植物油脂、卵黄（卵を含む）、醸造酢）、□□、××<br>
---------------------------------<br>
<br>
上記のうちの「食用植物油脂」「卵黄」「醸造酢」が該当場所です。<br>
<br>
この表示名は、自由記述で入力することができます。`;

export const similarNameAlert =
`
この原材料は「添加物」に該当する可能性が高いです！<br>
<br>
添加物の場合、添加物として登録する必要があるので、下記「添加物」に入力ができないか、一度確認してみてください。
`;

export const allergenToOmitDisplay =
  `「原材料表示名」に「牛肉」、「アレルゲン」に「牛肉」を選択すると、以下のようになることがあります。<br>
<br>
---------------------------------<br>
【原材料名】○○、△△、牛肉（牛肉を含む）、□□、××<br>
---------------------------------<br>
<br>
このように明らかに「原材料表示名」に「アレルゲンの名称」が含まれている場合、アレルゲンの個別表示に限って、単純に「牛肉」と表示することが可能となります。<br>
<br>
---------------------------------<br>
【原材料名】○○、△△、牛肉、□□、××<br>
---------------------------------<br>
<br>
ただし、アレルゲンの一括表示では「（一部に 牛肉 を含む）」との表記は必要となります。<br>
<br>
---------------------------------<br>
【原材料名】○○、△△、牛肉、□□、××、（一部に 牛肉 を含む）<br>
---------------------------------<br>
<br>
他にも「ビーフ（牛肉を含む）」のような「代替表記」や、「牛すじ（牛肉を含む）」のような「拡大表記」と呼ばれる場合にも省略が可能です。<br>
<br>
これらアレルゲンの個別表示での省略し、アレルゲンの一括表示では表記したい場合、この項目を選択してください。<br>
<br>
ただし、アレルゲンの表示は消費者の生命を左右するため、明確にアレルゲンを含まれているか判断が難しい場合は、選択しないでください。<br>
<br>
特に、卵黄・卵白に対して、アレルゲンの「卵」を省略することは、認められていません。例）✕ 卵白、◯ 卵白（卵を含む）`;

export const gmo =
`日本の「遺伝子組換えでない」に対する意味が、2022年度以前と2023年度以降で異なります。

法令改正の詳細については、<a class='u-link' href='https://blog.smashoku.com/ja/jp-gmo-regulation-2023_and_comply/' target="_blank">コチラ</a>をご覧ください。

この改正に伴って、当システムでは、2023年4月以前の「遺伝子組み換え」を「（廃止予定）遺伝子組換えでない（2023年度改正前基準）」に振り替えを行いました。

お手数をおかけしますが、「（廃止予定）遺伝子組換えでない（2023年度改正前基準）」の項目については、取引先に確認を取るなどを行った上で、実情に沿って「分別生産流通管理済み（混入率：5％以下）」「遺伝子組換えでない（混入率：0％）」などに選択し直してください。

なお、「（廃止予定）遺伝子組換えでない（2023年度改正前基準）」については、2025年3月末時点で「分別生産流通管理済み（混入率：5％以下）」に振り分けられる予定です。`.replace(/\n/g, '<br>');

export const hydrogenatedOil =
`一部の国では「部分水素添加油脂」「完全添加水素油脂」が、規制されています。

【日本】トランス脂肪酸に関する各国・地域の規制状況
https://www.maff.go.jp/j/syouan/seisaku/trans_fat/overseas/overseas.html

◆ 規制理由

油脂の加工「硬化処理」などを行う際、「トランス脂肪酸」に変化することがあります。

この「トランス脂肪酸」が、一定の基準を超えて混入する場合、健康に影響することが報告されています。`.replace(/\n/g, '<br>');

export const selectAdditive =
`商品でこの原材料を使用した際、商品の「原材料」に記載する「添加物」を記述してください。<br>
<br>
選択肢にないものは、法律により使用することが禁止されています。<br>
<br>
以下に該当するものも、入力してください。商品の作成時に個別に非表示に設定することができます。<br>
<br>
① 栄養強化目的<br>
文字通り「栄養の強化」を目的として添加されるもののことを言います。<br>
<br>
② 加工助剤<br>
1. 当該食品の完成前に除去されるもの<br>
2. 当該食品に通常含まれる成分と同じ成分になり、かつ成分の量を明らかに増加させないもの<br>
3. 含まれる量が少なく、かつ当該食品に影響を及ぼさないもの<br>
<br>
③ キャリーオーバー<br>
1. 当該食品の原材料の製造または加工の過程において使用されるもの<br>
2. 当該食品の製造または加工の過程において使用されないもの<br>
3. 当該食品において添加物が効果を発揮する量よりも少ない量しか含まれていないもの`;

export const additiveOrigin =
`アレルゲンから作られた添加物の場合、食品表示に「レシチン（大豆由来）」のように表示することが、食品表示法により定められています。<br>
<br>
使用する食品が、アレルゲン由来かどうかは、食品表示や、規格書（由来記載のない場合は、購入者に対して問い合わせをしてください）にて確認することが、一般的です。`;

export const additivePurpose =
`一部の添加物には「用途の表示」が義務付けられています。<br><br>
その場合、ここから実際の使用している「用途」を選択してください。<br><br>
表示の必要がない場合には「選択候補なし」と表示されます。`;

export const invalidAdditive =
`選択されている添加物を商品に使用すると、高確率で違法となります。<br><br>
この原材料を仕入れている卸や製造メーカーから、具体的に「添加物の物質名」をお問い合わせください。<br><br>
ここについてご不明点ございましたら、いつでも「画面右下 > 問い合わせ・ご質問」からお問い合わせください。`;

export const referredNutrition =
`スーパーマーケットで売られている「にんじん」など、原材料の栄養成分がよく分からない場合。<br><br>
また、「みかんのシロップ漬け」の「シロップ」部分だけ使用する場合など、詳細な栄養を知りたい場合。<br><br>
文部科学省が公開している「食品成分データベース」の数値を引用することができます。<br><br>
()で囲われた数値は、文科省が推定値としている数値です。<br><br>
[]で囲われた数値は、文科省が算出推定値としている数値です。<br><br>
ここにないものは、原材料の商品企画書や、その他の情報から「熱量」などの数値を手入力してください。`;

export const isNutritionOverrideOnPreproduct =
  `製造する「中間原材料（半製品、仕掛品）」に対して、栄養成分の分析などを行い、栄養成分の数値を算出した場合、原材料からの計算結果に対して、数値を上書きをすることができます。`;

export const intraCode = '管理のためにIDが振られている場合は、ここに管理IDを設定してください。';

export const tag = 'この原材料の管理用のタグを設定すると、検索や整理などが効率よく行えます。';

export const referSpec =
  `「スマート食品企画書」（ https://smashoku.com/spec ）を利用している場合、回収した規格書から自動的に原材料を追加することができます。

原材料の内容については、規格書が更新されても自動的に更新されません。

更新したい場合は、右記から更新したい版を選択した上で「左記を反映」ボタンを押してください。

◆ 自動的に追加したくない場合

画面右上のアカウント名 > 組織設定 > 「スマート規格書で回収・作成した原材料を自動でスマート食品表示の原材料に追加する」をOFFにしてください。`.replace(/\n/g, '<br>');


export const itemVisible =
`この原材料・食品添加物を商品の「食品表示（一括表示）」の「原材料名」に表示する必要がない場合、こちらを「非表示」にすることで、設定することができます。

非表示の理由は、商品画面で設定することができます。`.replace(/\n/g, '<br>');
